<template>
    <div style="position:fixed;right:75px;bottom:10px;height:200px;width:130px;background:#fff;border:1px dashed #ccc;" v-if="false" :style="{width:close==false?'130px':'0',height:close==false?'200px':'0'}">
        <div style="color:blue;width:40px;height:20px;background:#fff;position:absolute;right:0px;top:-20px;cursor:pointer;text-align:center;line-height:20px;">
            <span v-if="close==false" @click="close=true">关闭</span>
            <span v-if="close==true" @click="close=false">显示</span>
        </div>
        <div style="overflow:hidden;">
            <img :src="qrcode" alt="" style="width:130px;height:130px;">
            <p style="height:30px;line-height:30px;text-align:center;font-size:16px;color:blue;">扫一扫进技术群</p>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            qrcode:require('../assets/img/home/qun_qrcode.jpg'),
            close:false,
        }
    },
    methods:{

    },
    mounted(){
        //初始化数据
        


    }
}
</script>

<style>

</style>